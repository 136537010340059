<template>
  <div>
    <group-title
      v-if="items.length"
      :selected-items="selectedItems"
      :items="items"
      @select="selectAll"
    >
      <ware-details-menu
        :ware-id="wareId"
        :selected="selectedItems"
        @fetch="fetchWareDetails"
      />
      <field-selector :type="type" />
      <show-only-filled
        :type="type"
        :items="items"
      />
    </group-title>
    <v-virtual-scroll
      ref="virtualScroll"
      :items="items"
      :height="virtualScrollHeight"
      :bench="5"
      :item-height="virtualScrollItemHeight"
      class="px-0 rounded ware-detail-virtual-scroll"
    >
      <template #default="{ item, index }">
        <ware-details-item
          :key="index"
          :ware="item"
          :index="index"
          :wares="items"
          :selected="selectedItems"
          :currency-letter="currencyLetter"
          :measure-unit-letter="measureUnitLetter"
          :ware-id="wareId"
          @add="addWareDetail"
          @select="selectElement"
          @copy="copyWareDetail"
        />
      </template>
    </v-virtual-scroll>
    <v-btn
      v-if="items.length"
      dense
      class="elevation-0 mt-2 ml-11"
      @click="addWareDetail(items.length + 1)"
    >
      <v-icon>mdi-plus</v-icon>
      Добавить товар
    </v-btn>
  </div>
</template>
<script>
import WareDetailsItem from "@/components/kdt/goods/ware-details/ware-details-item.vue";
import GroupTitle from "@/components/kdt/goods/ware-details/group-title.vue";
import wareDetailGroupSelectMixin from "@/mixins/ware-detail-group-select.mixin";
import WareDetailsMenu from "@/components/kdt/goods/ware-details/ware-details-menu.vue";
import { mapGetters } from "vuex";
import FieldSelector from "@/components/kdt/goods/ware-details/field-selector.vue";
import wareDetailsVirtualScrollMixin from "@/components/kdt/goods/ware-details/ware-details-virtual-scroll.mixin";
import { eventBus } from "@/bus";
import ShowOnlyFilled from "@/components/kdt/goods/ware-details/show-only-filled.vue";
import { UPDATE_WARE_DETAIL } from "@/components/kdt/goods/ware-details/events";
import { WARE_TYPE } from "@/components/kdt/goods/ware-details/fields";

export default {
  components: {
    FieldSelector,
    GroupTitle,
    WareDetailsItem,
    WareDetailsMenu,
    ShowOnlyFilled,
  },
  mixins: [wareDetailGroupSelectMixin, wareDetailsVirtualScrollMixin],
  props: {
    wareId: {
      required: true,
      type: Number,
    },
    items: {
      required: true,
      type: Array,
    },
    types: {
      required: true,
      type: Array,
    },
    type: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      selectedWares: "kdt/getSelectedWares",
      requests: "kdt/getRequests",
    }),
    currencyLetter() {
      return this.selected.about_wares.invoice_currency_letter;
    },
    ware() {
      return this.selectedWares.find((ware) => ware.id === this.wareId);
    },
    measureUnitLetter() {
      return this.ware?.details_measure_unit_letter ?? null;
    },
  },
  created() {
    eventBus.$on(UPDATE_WARE_DETAIL, this.updateWareDetail);
  },
  beforeDestroy() {
    eventBus.$off(UPDATE_WARE_DETAIL, this.updateWareDetail);
  },
  methods: {
    fetchWareDetails() {
      const { value, fetchActionName } = this.types.find(
        (type) => type.value === WARE_TYPE
      );
      this.$emit("fetch", { value, fetchActionName });
    },
    updateWareDetail({ index, item }) {
      this.$emit("update", { type: WARE_TYPE, index, item });
    },
    copyWareDetail(id) {
      Promise.all(this.requests)
        .then(() => {
          const index = this.items.length;
          return this.$store
            .dispatch("kdt/copyWareDetail", { id, index })
            .then((res) => {
              this.$emit("add", { type: WARE_TYPE, item: res.data });
            });
        })
        .catch(() => this.$error());
    },
    addWareDetail(lastWareSortIndex) {
      const sortIndex = lastWareSortIndex + 1;
      this.$store
        .dispatch("kdt/createWareDetails", {
          wareId: this.wareId,
          sortIndex,
        })
        .then((res) => {
          this.$emit("add", { type: WARE_TYPE, item: res.data });
        })
        .catch(() => this.$error());
    },
  },
};
</script>
