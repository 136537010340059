<template>
  <v-row class="py-2 mx-0">
    <div>
      <v-checkbox
        color="teal lighten-2"
        class="mt-6 ml-3"
        :input-value="selected.includes(item.id)"
        :disabled="disabledView"
        @change="select"
      />
    </div>
    <v-col
      :id="block_id"
      v-click-outside="triggerOnFocus"
      :class="{ 'blocked-box': disabledView }"
    >
      <v-row>
        <v-col
          v-show="isSelectedField(fields.description.value)"
          cols="6"
          class="py-0"
        >
          <label>{{ fields.description.text }}</label>
          <v-text-field
            v-model="item.description"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          cols="3"
          class="py-0"
        >
          <v-row>
            <v-col
              v-show="isSelectedField(fields.ware_quantity.value)"
              cols="6"
            >
              <label>{{ fields.ware_quantity.text }}</label>
              <input-numeric
                v-model.lazy="item.ware_quantity"
                :fraction="6"
                :suffix="measureUnitLetter"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col
              v-show="isSelectedField(fields.article.value)"
              cols="6"
            >
              <label>{{ fields.article.text }}</label>
              <v-text-field
                v-model="item.article"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-show="isSelectedField(fields.invoice_cost.value)"
          cols="3"
          class="py-0"
        >
          <label>{{ fields.invoice_cost.text }}</label>
          <div class="d-flex">
            <input-numeric
              v-model.lazy="item.invoice_cost"
              :suffix="currencyLetter"
              :fraction="2"
              outlined
              dense
              hide-details
              class="cost_double_1"
            />
            <input-numeric
              :value="price"
              disabled
              :fraction="3"
              outlined
              dense
              hide-details
              class="cost_double_2"
            />
          </div>
        </v-col>
        <v-col
          v-show="isSelectedField(fields.model_name.value)"
          class="py-0"
          cols="3"
        >
          <label>{{ fields.model_name.text }}</label>
          <v-text-field
            v-model="item.model_name"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.category.value)"
          class="py-0"
          cols="2"
        >
          <label>{{ fields.category.text }}</label>
          <v-text-field
            v-model="item.category"
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col
          v-show="isSelectedField(fields.sort.value)"
          class="py-0"
        >
          <label>{{ fields.sort.text }}</label>
          <v-text-field
            v-model="item.sort"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.standard.value)"
          class="py-0"
        >
          <label>{{ fields.standard.text }}</label>
          <v-text-field
            v-model="item.standard"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.release_date.value)"
          class="py-0"
        >
          <label>{{ fields.release_date.text }}</label>
          <custom-date-picker v-model="item.release_date" />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.serial_numbers.value)"
          cols="2"
          class="py-0"
        >
          <label>{{ fields.serial_numbers.text
          }}<span
            v-if="serialNumbersCount"
            class="font-weight-bold"
          >({{ serialNumbersCount }})</span></label>
          <v-textarea
            v-model.trim="item.serial_numbers"
            rows="1"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.gross_weight.value)"
          cols="2"
          class="py-0"
        >
          <label>{{ fields.gross_weight.text }}</label>
          <input-numeric
            v-model.trim="item.gross_weight"
            :fraction="2"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.net_weight.value)"
          cols="2"
          class="py-0"
        >
          <label>{{ fields.net_weight.text }}</label>
          <input-numeric
            v-model.trim="item.net_weight"
            :fraction="2"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col
          v-show="isSelectedField(fields.net_weight_no_pack.value)"
          cols="2"
          class="py-0"
        >
          <label>{{ fields.net_weight_no_pack.text }}</label>
          <input-numeric
            v-model.trim="item.net_weight_no_pack"
            :fraction="2"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-row>
    </v-col>
    <div class="actions">
      <v-btn
        text
        min-width="40px"
        width="40px"
        @click="search"
      >
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <v-btn
        text
        :disabled="disabledView"
        min-width="40px"
        width="40px"
        @click="copyWareDetail"
      >
        <v-icon> mdi-content-copy </v-icon>
      </v-btn>
      <!-- <v-btn
        v-if="wares.length - 1 === index"
        :disabled="disabledView"
        text
        min-width="40px"
        width="40px"
        @click="addWareDetail"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn> -->
    </div>
  </v-row>
</template>
<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import InputNumeric from "@/components/ui/input-numeric.vue";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { getFirstSeparator, googleImage } from "@/helpers/text";
import { normalizeFloat } from "@/helpers/math";
import { mapGetters } from "vuex";
import {
  FIELDS,
  preparedFields,
} from "@/components/kdt/goods/ware-details/fields";
import { UPDATE_WARE_DETAIL } from "@/components/kdt/goods/ware-details/events";
import { eventBus } from "@/bus";

export default {
  components: { InputNumeric, CustomDatePicker },
  mixins: [blockAutoUpdateMixin],
  props: {
    ware: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    wares: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    currencyLetter: {
      validator: (v) => typeof v === "string" || v === null,
      required: true,
    },
    measureUnitLetter: {
      validator: (v) => typeof v === "string" || v === null,
      required: true,
    },
    wareId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      block_id: null,
      item: {
        sort_index: null,
        id: null,
        ware_id: null,
        description: null,
        article: null,
        ware_quantity: null,
        invoice_cost: null,
        model_name: null,
        category: null,
        sort: null,
        standard: null,
        release_date: null,
        serial_numbers: null,
        gross_weight: null,
        net_weight: null,
        net_weight_no_pack: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedFields: "kdtSettings/getWareDetailsFields",
      disabledView: "kdt/getVisibility",
      selectedWares: "kdt/getSelectedWares",
    }),
    fields() {
      return preparedFields(FIELDS.ware);
    },
    price() {
      const cost = this.item.invoice_cost;
      const quantity = this.item.ware_quantity;
      return cost && quantity
        ? normalizeFloat({ value: cost / quantity, afterPoint: 2 })
        : 0;
    },
    priceSuffix() {
      return this.currencyLetter ? `${this.currencyLetter}/шт` : null;
    },
    serialNumbersCount() {
      if (!this.item.serial_numbers) return 0;
      const separator = getFirstSeparator(this.item.serial_numbers);
      return separator
        ? this.item.serial_numbers?.split(separator).filter((v) => v).length
        : 0;
    },
  },
  watch: {
    ware: {
      handler() {
        this.setFields();
        this.setBlockId();
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.saveOnScroll();
  },
  methods: {
    saveOnScroll() {
      if (this.hasChanges && this.fields_been_visited)
        this.uploadData()
          .then(() => this.$success("Обновлено"))
          .catch(() => this.$error());
    },
    isSelectedField(field) {
      if (!this.selectedFields.ware.length) return true;
      return this.selectedFields.ware.includes(field);
    },
    getSearchString() {
      const prepared = this.item.pick([
        "description",
        "article",
        "model_name",
        "category",
        "sort",
        "standard",
      ]);
      const editableWare = this.selectedWares.find(
        (item) => item.id === this.wareId
      );
      let preparedString = Object.values(prepared).join(" ");
      if (editableWare.trade_mark === null || editableWare.trade_mark === "") {
        preparedString = preparedString.replace(
          /^/,
          `${editableWare.manufacturer} `
        );
      } else {
        preparedString = preparedString.replace(
          /^/,
          `${editableWare.trade_mark} `
        );
      }
      return preparedString;
    },
    search() {
      googleImage(this.getSearchString());
    },
    select() {
      this.$emit("select", this.item.id);
    },
    readyToUpdate() {
      this.fields_been_visited = true;
      this.hasChanges = true;
    },
    setBlockId() {
      this.block_id = `kdt-ware-detail-${this.index}`;
    },
    setFields() {
      Object.keys(this.item).forEach((key) => {
        if (key in this.ware) {
          this.item[key] = this.ware[key];
        }
      });
    },
    addWareDetail() {
      this.$emit("add", this.ware.sort_index);
    },
    copyWareDetail() {
      this.$emit("copy", this.ware.id);
    },
    uploadData() {
      const payload = convertEmptyStringsToNull(this.item);
      return this.$store
        .dispatch("kdt/updateWareDetails", payload)
        .then((res) => {
          const [item] = res.data;
          eventBus.$emit(UPDATE_WARE_DETAIL, { item, index: this.index });
          return res;
        });
    },
  },
};
</script>
<style scoped>
.actions {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 0 10px;
  border-left: 1px dotted grey;
  width: 80px;
}
</style>
